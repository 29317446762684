import './App.css';
import dp from './DP.png';

function App() {
  return (
    <div className="App">
      <div className="app-content">
      
      <h1>Thank You for the Journey.</h1>
      <p>It was a dream for us to create an open online gaming orchestration platform. Pursuing the dream we created ebattle.online and creator.ebattle.online.</p>
      <p>With over 2500+ active gamers and 20+ creators, we were able to create a small community that was passionate about gaming and creating games.</p>
       <p>Unfortunately some dreams are not meant to be. We are sad to announce that we have to shut down both the websites due to lack of proper product market fit.</p>

      <p>To the gamers and creators who were part of us, Thank you! 🙏 <br />The enthusiasm and determination that was displayed by you on our platform,
        we are sure you would succeed in your future endeavors. We are grateful for the time you spent with us and we hope that you will continue to create and play games.
      </p>
      <p>Special shoutout 🙌 to Aditya, Ritwik and Offence esports who believed in us when we were starting.</p>
      <p>Thank you for the journey. 🚀</p>
      <p>Regards,<br />Saugandh and Ashish from ebattle.online</p>
      <img className="app-image" src={dp} alt="ebattle-logo" />
      </div>
    </div>
  );
}

export default App;
